import { Typography } from "@mui/material";
import dayjs from "dayjs";


const SlotsList = ({ startDay, endDay, dates }) => {
  
  const days = [];
  var startDate = startDay.startOf('day');
  while (startDate.isBefore(endDay.endOf('day'))) {
    days.push(startDate);
    startDate = startDate.add(1, 'day')
  }
  
  const hasDates = (day) => {
    const start = day.valueOf();
    const end = day.add(1, 'day').valueOf()
    return dates.find(date => start <= date && date < end)
  }

  const buildSlots = (day) => {
    const start = day.valueOf();
    const end = day.add(1, 'day').valueOf()
    const times = dates.filter(date => start <= date && date < end).sort((a, b) => a - b).map(item => dayjs(item).format("HH:mm"))
    return <Typography key={day} sx={{ m: 1 }}>{ `${day.format("DD.MM")}: ${times.join(", ")}` }</Typography>
  }

  return (
    <div>
      {
        days.filter(day => hasDates(day)).map(day => buildSlots(day))
      }
    </div>
  );
}

export default SlotsList;